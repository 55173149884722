<!-- When this button is added to the new app, it also should be added to the view aviw_Pivots_ApplicationsLookup-->
<!-- When make changes also update PivotReportsDropdown_Libs.vue -->
<template>
    <div class="dropdown">
        <button type="button" 
                class="btn btn-sm btn-outline-primary dropdown-toggle" 
                :disabled="dsPivotApplicationsListDropdown.data.length == 0" 
                data-bs-toggle="dropdown" 
                data-bs-auto-close="outside" 
                aria-expanded="false"
                style="pointer-events: auto;"
                :title="dsPivotApplicationsListDropdown.data.length == 0 ? $t('No Pivots have been configured for this application in the Current Context') : $t('Pivot Reports')"
        >
            {{ $t('Pivot Reports') }}
        </button>
        
        <ul class="dropdown-menu">
            <li v-for="pivot in dsPivotApplicationsListDropdown.data">
                <a class="dropdown-item" :href="`/pivot?ReportID=${pivot.Report_ID}&LoadReportData=true`" target="_blank" rel="noopener norefefer" v-if="getLayoutsForReport(pivot.Report_ID).length < 2">
                    {{ pivot.PivotTitle }}
                </a>
                <div :id="pivot.Report_ID" class="btn-group dropend w-100" v-if="getLayoutsForReport(pivot.Report_ID).length > 1 && attachEvents(pivot.Report_ID)">
                    <a class="dropdown-item d-flex w-100" href="#" data-bs-toggle="dropdown" aria-expanded="false">
                        <span class="">{{ pivot.PivotTitle }}</span>
                        <i class="bi bi-caret-right-fill ms-auto" style="font-size: 12px;"></i>
                    </a>
                    <ul class="dropdown-menu" v-if="getLayoutsForReport(pivot.Report_ID).length > 1" style="position:absolute; left: 100%;">
                        <li v-for="layout in getLayoutsForReport(pivot.Report_ID)">
                            <a class="dropdown-item" :href="`/pivot?ReportID=${pivot.Report_ID}&LayoutID=${layout.ID}&LoadReportData=true`" target="_blank" rel="noopener norefefer">{{ layout.Title }}</a>
                        </li>
                    </ul>
                </div>
            </li>
        </ul>
    </div>
</template>

<script setup>
    import { getOrCreateDataObject } from 'o365.vue.ts';
    import context from 'o365.modules.Context.ts';
    import { useDataObjectEventListener } from 'o365.vue.composables.EventListener.ts';

    const props = defineProps({
        appID: String
    });

    const dsPivotApplicationsListDropdown = getOrCreateDataObject({
        id: 'dsPivotApplicationsListDropdown',
        viewName: 'aviw_Pivots_Applications',
        maxRecords: 100,
        whereClause: "App_ID = '" + props.appID + "'",
        loadRecents: false,
        distinctRows: true,
        fields: [
            { name: "Report_ID", type: "number"},
            { name: "App_ID", type: "string"},
            { name: "PivotTitle", type: "string"}
        ]
    });

    const dsPivotLayoutsListDropdown = getOrCreateDataObject({
        id: 'dsPivotLayoutsListDropdown',
        viewName: 'aviw_Pivots_Layouts',
        maxRecords: 100,
        whereClause: "1=0",
        loadRecents: false,
        distinctRows: true,
        fields: [
            { name: "ID", type: "number"},
            { name: "ReportID", type: "number"},
            { name: "Title", type: "string"}
        ]
    });

    dsPivotApplicationsListDropdown.load();

    function getLayoutsForReport(reportID){
        return dsPivotLayoutsListDropdown.data.filter((item) => item.ReportID == reportID);
    }

    function attachEvents(id){
        setTimeout(() => {
            document.getElementById(id)?.addEventListener("mouseover", ()=>{
                document.getElementById(id)?.lastElementChild.classList.add('show');
            });

            document.getElementById(id)?.addEventListener("mouseout", ()=>{
                document.getElementById(id)?.lastElementChild.classList.remove('show');
            });
        }, "600");
        return true;
    }

    useDataObjectEventListener(dsPivotApplicationsListDropdown, 'DataLoaded', (data, options) => {
        let idList = dsPivotApplicationsListDropdown.data.filter((value, index, array) => array.indexOf(value) === index);
        if(idList.length > 0){
            let where = [];
            idList.forEach((i)=>{
                where.push(i.Report_ID);
            });
            dsPivotLayoutsListDropdown.recordSource.whereClause = "ReportID IN (" + where.join(",") + ")";
            dsPivotLayoutsListDropdown.load();
        }
    });

    context.on('Change', () => {
        dsPivotApplicationsListDropdown.load();
    });
</script>